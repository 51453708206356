import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';

const formFieldExpr = /^field_(\d+)_(\d+)$/;

function transformIn(value) {
  if (typeof value === 'object') {
    const { field, form } = value;
    const field_name_components = ['field', field, form];
    return field_name_components.join('_');
  } else {
    return value;
  }
}

function transformOut(value) {
  if (typeof value === 'string' && formFieldExpr.test(value)) {
    const [, field, form] = formFieldExpr.exec(value);
    return { form, field, type: 'field' };
  } else {
    return value;
  }
}

class FieldSelectSwitcher extends Component {
  constructor(props) {
    super(props);
    this.state = { useInput: this.getInitialInputState(props.value) };
    this.handleChange = this.handleChange.bind(this);
    this.toggleSelect = this.toggleSelect.bind(this);
  }

  getInitialInputState(_value) { // eslint-disable-line no-unused-vars
    // This method should be overridden in child classes
    throw new Error('getInitialInputState must be implemented in child class');
  }

  renderInput(_transformedValue) { // eslint-disable-line no-unused-vars
    // This method should be overridden in child classes
    throw new Error('renderInput must be implemented in child class');
  }

  render() {
    const { value, options = [] } = this.props;
    const { useInput } = this.state;

    const transformedValue = transformIn(value);
    const fieldOptions = options.map(({ label, value }) => ({ label, value }));

    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {useInput ? (
          this.renderInput(transformedValue)
        ) : (
          <select
            value={transformedValue}
            onChange={(e) => this.handleChange(e.target.value)}
          >
            <option value="">Select an option</option>
            {fieldOptions.map(({ label, value }) => (
              <option key={value} value={value}>{label}</option>
            ))}
          </select>
        )}
        <Button
          icon="exchange"
          size="mini"
          basic
          style={{ marginLeft: '2px' }}
          onClick={this.toggleSelect}
        />
      </div>
    );
  }

  handleChange(value) {
    const { name, onChange } = this.props;
    const transformedValue = transformOut(value);
    onChange({ target: { name, value: transformedValue } });
  }

  toggleSelect() {
    this.setState((prevState) => ({
      useInput: !prevState.useInput,
    }));
    this.handleChange('');
  }
}

FieldSelectSwitcher.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
};

export default FieldSelectSwitcher;
